import { usewpdataSelectors } from '../../lib/wpdataState/selectors';
import s from './styles/benefits.module.scss';
const Benefits = ({ variant }: { variant: string }) => {
  const { hero, Settings } = usewpdataSelectors();

  const renderBenefit = (benefit: {
    feature_image: string;
    tr_feature_title: string;
    tr_feature_description: string;
  }) => {
    return (
      <div className={`${s.benefits__item}`}>
        <img src={benefit.feature_image} alt={benefit.tr_feature_title} />
        <div className={s.benefits__titles}>
          <div className={s.benefits__titles__main} style={{ color: Settings.primary_color_1 }}>
            {benefit.tr_feature_title}
          </div>
          <div className={s.benefits__titles__sub} style={{ color: Settings.primary_color_2 }}>
            {benefit.tr_feature_description}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`${s.benefits} ${variant === 'productHero' ? s['benefits__productHero'] : ''}`}>
      {hero.benefits && hero.benefits.map((benefit) => renderBenefit(benefit))}
    </div>
  );
};

export default Benefits;
