import Benefits from './Benefits';
import CtaButton from './CtaButton';
import s from './styles/hero.module.scss';
import HeroTitles from './HeroTitles';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Hero = () => {
  const { hero, Settings, productsSection } = usewpdataSelectors();

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: true,
  };

  const shopProducts = productsSection?.products;
  const productsWithImages = shopProducts?.filter((product) => product.hero_slider_images);

  return (
    <div className={s.hero__wrapper} style={{ background: Settings.background_color_1 }}>
      <div className={s.hero}>
        <span className={s.hero__1st}>
          <HeroTitles />
        </span>
        <div className={s.hero__3rd}>
          <Benefits variant={''} />
          <CtaButton />
        </div>
        {productsWithImages ? (
          <div className={`${s.hero__slider}`}>
            <Slider {...settings}>
              {productsWithImages.map((product, index) => (
                <a href={`${window.checkout_url.replace('%s', product.slug)}`} key={index}>
                  <img className={`mon-img-566-w`} src={product.hero_slider_images} alt={Settings.brandName} />
                </a>
              ))}
            </Slider>
          </div>
        ) : (
          hero.hero_image && (
            <img className={`${s.hero__img} mon-img-566-w`} src={hero.hero_image} alt={Settings.brandName} />
          )
        )}
      </div>
    </div>
  );
};

export default Hero;
