import React, { lazy } from 'react';
import './index.css';
import './styles/styles.scss';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import { Header } from './components/Header/Header';
import Hero from './components/Hero/Hero';
import Support from 'components/Zendesk';
import LazyComponent from 'components/LazyLaod';

const Layout = () => {
  const { Settings } = usewpdataSelectors();
  const showReviewsSection = !!Settings?.showReviewsSection;

  return (
    <>
      <div style={{ width: '100%' }}>
        <Header />
      </div>
      <Hero />
      <LazyComponent Component={lazy(() => import('components/SlidingTextBar/SlidingTextBar'))} />
      <LazyComponent Component={lazy(() => import('components/ProductsSection/ProductSection'))} />
      <LazyComponent Component={lazy(() => import('components/SlidingTextBar/SlidingTextBar'))} />
      <LazyComponent Component={lazy(() => import('components/FAQ/FAQ'))}></LazyComponent>
      {showReviewsSection && <LazyComponent Component={lazy(() => import('components/CustomerReviews'))} />}
      <LazyComponent Component={lazy(() => import('components/Footer/Footer'))} />
      <div>
        <Support />
      </div>
    </>
  );
};

export default Layout;
