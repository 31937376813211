import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import { CSSProperties } from 'react';

type CTAProps = {
  text: string;
  className: string;
  attribute?: string;
  style?: CSSProperties;
  href: string;
};

const Button = ({ text, className, attribute, style, href }: CTAProps) => {
  const { Theme } = usewpdataSelectors();

  return (
    <a data-cy={attribute} style={style} href={href} data-theme={Theme?.button} className={`${className}`}>
      {text}
    </a>
  );
};

export default Button;
