import s from './LinkList.module.scss';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';

export const LinkList = () => {
  const { header, Settings } = usewpdataSelectors();

  const handleZendeskClick = () => {
    window.zE.show();
    window.zE.activate();
  };

  const showReviewsSection = !!Settings?.showReviewsSection;

  return (
    <>
      {header.headerNavigationLinks &&
        header.headerNavigationLinks.map((item, index) => {
          if (item.link_type === 'custom' && item.custom_link.custom_link.includes('#reviews') && !showReviewsSection) {
            return null;
          }

          return (
            <li className={`${s.linkList__listItem} ${index === 0 && 'linkList__firstChild'}`} key={index}>
              {item.link_type === 'custom' ? (
                item.custom_link.custom_link.includes('zendesk') ? (
                  <button
                    style={{ background: 'none', border: 'none', cursor: 'pointer', margin: '0 auto' }}
                    className={s.linkList__listItemText}
                    onClick={() => {
                      handleZendeskClick();
                    }}
                  >
                    <span className={s.linkList__listItemText} style={{ color: Settings.primary_color_2 }}>
                      {item.custom_link.tr_custom_link_label}
                    </span>
                  </button>
                ) : (
                  <a href={item.custom_link.custom_link} className={s.linkList__listLink}>
                    <span className={s.linkList__listLink} style={{ color: Settings.primary_color_2 }}>
                      {item.custom_link.tr_custom_link_label}
                    </span>
                  </a>
                )
              ) : (
                <a
                  href={item.page_link.page_link_uri}
                  className={`${s.linkList__listLink} ${s.linkList__listItemText}`}
                >
                  {item.page_link.tr_page_link_label}
                </a>
              )}
            </li>
          );
        })}
    </>
  );
};
