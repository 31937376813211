import { wpData } from './wpDataType';
import { createSlice } from '@reduxjs/toolkit';

const root: any = window?.post_template;
const clean: any = window.clean_settings;
const settings: any = window.general_settings;

const initialState: wpData = {
  settings: {
    id: settings.id,
    templateRoot: window.template_root,
    themeRoot: window.theme_root,
    checkoutUrl: window.checkout_url,
    formattedString: window.formattedString,
    brandName: settings.shop_title,
    domain: settings.domain,
    company_name: settings.company_name,
    konnektive_campaign_id: settings.konnektive_campaign_id,
    address: settings.address,
    phone: settings.phone,
    email: settings.support_email,
    vat_id: settings.vat_id,
    default_currency: settings.default_currency,
    primary_color_1: settings.primary_color_1,
    paypal_merchant_id: settings.paypal_merchant_id,
    primary_color_2: settings.primary_color_2,
    background_color_1: settings.background_color_1,
    background_color_2: settings.background_color_2,
    product_grid_background_color: settings.product_grid_background_color,
    background_dark_color: settings.background_dark_color,
    text_color_dark: settings.text_color_dark,
    text_color_white: settings.text_color_white,
    isHeroVisible: true,
    showReviewsSection: settings.show_reviews_section_in_shop_home_page,
    showReachBadgeInCheckout: settings.show_reach_badge_in_checkout_page,
    ctaTextColor: settings.cta_text_color,
    ctaBackgroundColor: settings.cta_background_color,
    ctaBorderBottomColor: settings.cta_border_bottom_color,
    ctaBackgroundHoverColor: settings.cta_background_hover_color,
    ctaBorderBottomHoverColor: settings.cta_border_bottom_hover_color,
    mainGradientColor1: settings.main_gradient_color_1,
    mainGradientColor2: settings.main_gradient_color_2,
  },
  cleanSettings: {
    cleanMode: clean,
  },
  theme: {
    heading: root?.products_grid_v1_components?.products_grid_v1_headings_theme,
  },
  layout: {
    middleBar: {
      display: root?.products_grid_v1_middleBar?.products_grid_v1_middleBar_show,
    },
    ribbon: {
      layout: '',
    },
    faq: {
      layout: '',
    },
    reviews: {
      display: true,
      layout: root?.products_grid_v1_reviewsList?.products_grid_v1_reviewsList_layout || '',
    },
  },
  header: {
    headerNavigationLinks: root?.products_grid_v1_navigation?.products_grid_v1_navigation_links,
    headerNavRightButtonText: root?.products_grid_v1_navigation?.tr_products_grid_v1_navigation_cta,
    styles: {
      background: root?.products_grid_v1_navigation?.products_grid_v1_navigation_background,
      textColor: root?.products_grid_v1_navigation?.products_grid_v1_navigation_linkColor,
      textHoverColor: root?.products_grid_v1_navigation?.products_grid_v1_navigation_linkHover,
      borderColor: root?.products_grid_v1_navigation?.products_grid_v1_navigation_borderColor,
    },
  },
  discountBar: {
    text: root.products_grid_v1_discountBar?.tr_products_grid_v1_discountBar_text,
    styles: {
      background: root.products_grid_v1_discountBar?.products_grid_v1_discountBar_background,
      textColor: root.products_grid_v1_discountBar?.products_grid_v1_discountBar_color,
    },
  },
  middleBar: {
    list: root?.products_grid_v1_middleBar?.products_grid_v1_middleBar_list,
    styles: {
      background: root?.products_grid_v1_middleBar?.products_grid_v1_middleBar_background,
      textColor: root?.products_grid_v1_middleBar?.products_grid_v1_middleBar_primaryColor,
    },
  },
  hero: {
    title: root?.products_grid_v1_hero?.tr_products_grid_v1_hero_heading,
    subtitle: root?.products_grid_v1_hero?.tr_products_grid_v1_hero_subheading,
    hero_image: root?.products_grid_v1_hero?.products_grid_v1_hero_staticImage,
    show_socials: root?.products_grid_v1_hero?.products_grid_v1_hero_showSocials,
    show_stockLeft: root?.products_grid_v1_hero?.products_grid_v1_hero_showStockLeft,
    divider_text: root?.products_grid_v1_hero?.tr_products_grid_v1_hero_dividerText,
    bought_this: root?.products_grid_v1_hero?.tr_products_grid_v1_hero_bought_this,
    instaBubbles: root?.products_grid_v1_hero?.products_grid_v1_hero_bubbles,
    chart_link: root?.products_grid_v1_hero?.products_grid_v1_hero_chartLink,
    chart_label: root?.products_grid_v1_hero?.tr_products_grid_v1_hero_chartLabel,
    free_label: root?.products_grid_v1_hero?.tr_products_grid_v1_hero_freeLabel,
    left_label: root?.products_grid_v1_hero?.tr_products_grid_v1_hero_leftLabel,
    availability_label: root?.products_grid_v1_hero?.tr_products_grid_v1_hero_limitedAvailability,
    each_label: root?.products_grid_v1_hero?.tr_products_grid_v1_hero_eachLabel,
    star_rating: {
      rating: root?.products_grid_v1_hero?.products_grid_v1_hero_score,
      rating_text: 'Excellent',
      ratings_count: '158',
      ratings_count_text: root?.products_grid_v1_hero?.tr_products_grid_v1_hero_reviewsLabel,
      ratings_count_text_mobile: root?.products_grid_v1_hero?.tr_products_grid_v1_hero_reviewsLabel_mobile,
    },
    faqList: root?.products_grid_v1_hero?.products_grid_v1_hero_faq,
    benefits: root?.products_grid_v1_hero?.products_grid_v1_hero_features,
    ctaButtonText: root?.products_grid_v1_hero?.tr_products_grid_v1_hero_ctaLabel,
    styles: {
      background: root?.products_grid_v1_hero?.products_grid_v1_hero_backgroundColor,
      textColorPrimary: root?.products_grid_v1_hero?.products_grid_v1_hero_textPrimary,
      textColorSecondary: root?.products_grid_v1_hero?.products_grid_v1_hero_textSecondary,
      checkIconColor: root?.products_grid_v1_hero?.products_grid_v1_hero_checkmarkicon,
      accentColor: root?.products_grid_v1_hero?.product_page_V6_hero_text_accent,
      bestSellerColor: root?.products_grid_v1_hero?.products_grid_v1_hero_background_best_seller,
      bestValueColor: root?.products_grid_v1_hero?.products_grid_v1_hero_background_best_value,
    },
  },
  productsSection: {
    title: root?.products_grid_v1_products?.tr_products_grid_v1_products_section_heading,
    reviewsText: 'Verified Reviews',
    products: window.productData.products,
    styles: {
      background: root?.products_grid_v1_products?.products_grid_v1_products_background,
      backgroundAccent: root?.products_grid_v1_products?.products_grid_v1_products_backgroundAccent,
      backgroundBadge: root?.products_grid_v1_products?.products_grid_v1_products_backgroundDiscount,
      textColorPrimary: root?.products_grid_v1_products?.products_grid_v1_products_textPrimary,
      textColorSecondary: root?.products_grid_v1_products?.products_grid_v1_products_textSecondary,
      accentColor: root?.products_grid_v1_products?.products_grid_v1_products_textAccent,
      badgeColor: root?.products_grid_v1_products?.products_grid_v1_products_background,
    },
  },

  slidingTextBar: {
    repeater: root?.products_grid_v1_benefitsRibbon?.products_grid_v1_benefitsRibbon_repeater,
    styles: {
      background: root?.products_grid_v1_benefitsRibbon?.products_grid_v1_benefitsRibbon_background,
      textColor: root?.products_grid_v1_benefitsRibbon?.products_grid_v1_benefitsRibbon_textColor,
    },
  },
  FAQ: {
    sectionTitle: root?.products_grid_v1_faq?.tr_products_grid_v1_faq_title,
    items: root?.products_grid_v1_faq?.products_grid_v1_faq_list_repeater,
    styles: {
      background: root?.products_grid_v1_faq?.products_grid_v1_faq_background,
    },
  },
  customerReviewsBlock: {
    title: root?.products_grid_v1_reviewsList?.tr_products_grid_v1_reviewsList_title,
    subtitle: root?.products_grid_v1_reviewsList?.tr_products_grid_v1_reviewsList_subtitle,
    reviews: root?.products_grid_v1_reviewsList?.products_grid_v1_reviewsList_repeater,
    showLessLabel: root?.products_grid_v1_reviewsList?.tr_products_grid_v1_reviewslist_loadLess,
    showMoreLabel: root?.products_grid_v1_reviewsList?.tr_products_grid_v1_reviewslist_readmore,
    verifiedLabel: root?.products_grid_v1_reviewsList?.tr_products_grid_v1_reviewsList_verified,
    recommendLabel: root?.products_grid_v1_reviewsList?.tr_products_grid_v1_reviewsList_recommendLabel,
    helpfulLabel: root?.products_grid_v1_reviewsList?.tr_products_grid_v1_reviewsList_helpfulLabel,
    loadSlides: root?.products_grid_v1_reviewsList?.products_grid_v1_reviewsList_index,
    styles: {
      background: root?.products_grid_v1_reviewsList?.products_grid_v1_reviewsList_background,
      buttonColor: root?.products_grid_v1_reviewsList?.products_grid_v1_reviewsList_buttonColor,
      bubbleBackground: root?.products_grid_v1_reviewsList?.products_grid_v1_reviewsList_initialBubbleColor,
    },
  },
  statistics: {
    reviewsLabel: root?.products_grid_v1_reviews_score.tr_products_grid_v1_reviews_score_reviewsLabel,
    writeReviewLabel: root?.products_grid_v1_reviews_score.tr_products_grid_v1_reviews_score_writeReviewLabel,
    reviewsAverage: root?.products_grid_v1_reviews_score.products_grid_v1_reviews_score_int,
    percentOfReviewers: root?.products_grid_v1_reviews_score.tr_products_grid_v1_reviews_score_percent,
    repeater: root?.products_grid_v1_reviews_score.products_grid_v1_reviews_score_qualities,
    styles: {
      barActive: root?.products_grid_v1_reviews_score.products_grid_v1_reviews_score_colorActive,
      barPassive: root?.products_grid_v1_reviews_score.products_grid_v1_reviews_score_colorPassive,
      textColorPrimary: root?.products_grid_v1_reviews_score.products_grid_v1_reviews_score_colorPassive,
      textColorSecondary: root?.products_grid_v1_reviews_score.products_grid_v1_reviews_score_textSecondary,
    },
  },
  footer: {
    benefits: root?.products_grid_v1_footer?.products_grid_v1_footer_benefits,
    text: root?.products_grid_v1_footer?.tr_footer_product_description,
    copyText: root?.products_grid_v1_footer?.tr_footer_copyrights_text,
    customerCareLabel: root?.products_grid_v1_footer?.tr_footer_customer_care_label,
    emailSupportLabel: root?.products_grid_v1_footer?.tr_footer_email_support_label,
    liveChatLabel: root?.products_grid_v1_footer?.tr_footer_chat_support_label,
    links: root?.products_grid_v1_footer?.footer_link_columns,
    footerLogo: root?.products_grid_v1_footer?.footer_logo,
  },
};

const wpdataSlice = createSlice({
  name: 'wpdata',
  initialState,
  reducers: {},
});

export default wpdataSlice.reducer;
