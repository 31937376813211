import { combineReducers, configureStore } from '@reduxjs/toolkit';
import wpdataReducer from './wpdataState/slice';

const rootReducer = combineReducers({
  wpdata: wpdataReducer,
});

const store = configureStore({
  reducer: rootReducer,
  devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
