import { TypedUseSelectorHook, useSelector } from 'react-redux';
import type { RootState } from '../store';

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const usewpdataSelectors = () => {
  const appSelector = useAppSelector;
  return {
    CleanSettings: appSelector((state: RootState) => state.wpdata.cleanSettings),
    Settings: appSelector((state: RootState) => state.wpdata.settings),
    Theme: appSelector((state: RootState) => state.wpdata.theme),
    Layout: appSelector((state: RootState) => state.wpdata.layout),
    DiscountBar: appSelector((state: RootState) => state.wpdata.discountBar),
    MiddleBar: appSelector((state: RootState) => state.wpdata.middleBar),
    header: appSelector((state: RootState) => state.wpdata.header),
    hero: appSelector((state: RootState) => state.wpdata.hero),
    productsSection: appSelector((state: RootState) => state.wpdata.productsSection),
    slidingTextBar: appSelector((state: RootState) => state.wpdata.slidingTextBar),
    Statistics: appSelector((state: RootState) => state.wpdata.statistics),
    FAQ: appSelector((state: RootState) => state.wpdata.FAQ),
    customerReviewsBlock: appSelector((state: RootState) => state.wpdata.customerReviewsBlock),
    footer: appSelector((state: RootState) => state.wpdata.footer),
  };
};
