import { usewpdataSelectors } from '../../lib/wpdataState/selectors';
import s from './styles/ctaButton.module.scss';
import Button from '../Button/CTA/Button';

const CtaButton = () => {
  const { hero, Settings } = usewpdataSelectors();
  return (
    <Button
      href="#products"
      style={{
        '--cta-text': Settings.ctaTextColor,
        '--cta-bg': Settings.ctaBackgroundColor,
        '--cta-bg-hover': Settings.ctaBackgroundHoverColor,
        '--cta-border-bottom': Settings.ctaBorderBottomColor,
        '--cta-border-bottom-hover': Settings.ctaBorderBottomHoverColor,
      }}
      className={s.button}
      attribute="cta-hero"
      text={hero.ctaButtonText}
    ></Button>
  );
};

export default CtaButton;
